export const handleApiErrors = (errorResponse: any): string[] => {
  let errorMessages: string[] = ["An unexpected error occurred."];

  // Check if this is an Axios error (with a response object)
  if (errorResponse?.response?.data?.error) {
    const errorData = errorResponse.response.data.error;

    // If the error is an object, we need to check and handle it appropriately
    if (Array.isArray(errorData)) {
      errorMessages = errorData.map(
        (err: any) => `${err.message} (Path: ${err.path.join(" > ")})`
      );
    } else if (typeof errorData === "object") {
      // If errorData is an object, we can convert it to a readable string
      errorMessages = [JSON.stringify(errorData)];
    } else {
      // Otherwise, treat it as a string
      errorMessages = [errorData];
    }
  } else if (errorResponse?.error && Array.isArray(errorResponse.error)) {
    // If the error comes from a different source (not Axios)
    errorMessages = errorResponse.error.map(
      (err: any) => `${err.message} (Path: ${err.path.join(" > ")})`
    );
  } else if (errorResponse?.message) {
    errorMessages = [errorResponse.message];
  } else if (
    errorResponse === "ERROR_LACK_CREDIT" ||
    errorResponse?.[0] === "ERROR_LACK_CREDIT"
  ) {
    errorMessages = [errorResponse];
  }

  return errorMessages;
};
